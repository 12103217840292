import React from 'react';
import Box from "@mui/material/Box";
import AddressCard from "../components/AddressCard";
import StaticMap from "../components/map/StaticMap";
import ContactPerson from "../page/ContactPerson";



function RightBar() {
    return (
<>
        <Box
            bgcolor="#7aacdf"
            flex={1}
            p={2}
            sx={{display:{md:"none",xs:"none",lg:"block"}, alignItems:'center',}}
        >

            <Box>
                <AddressCard/>
                <StaticMap/>
                <ContactPerson />

            </Box>
        </Box>

    </>
    );
}

export default RightBar;
