import React from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

function Impressum() {
    return (
        <div
        >
<Box >
            <Box sx={{margin:5,justifyContent:"center", width:{sx:'100vh',lg:'75vh'}}}>
            <Typography fontSize="1.3rem" color="text.secondary">

               Mentions légales:

            </Typography>
            <br></br>
            <Typography paragraph fontSize="1.1rem" color="text.secondary">Présentation du site: </Typography>
            <Typography paragraph>
                En vertu des lois en vigueur, il est précisé aux utilisateurs du site www.iwm.ch l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :

                Le présent site est édité par:         </Typography>
            <Typography paragraph> IWM AG, Rue Pré-Bérard 26, 1870 Monthey

            </Typography>
            <Typography paragraph fontSize="1.1rem" color="text.secondary">Responsable de la publication et webmaster :</Typography>
            <Typography paragraph >
                Sandro Imesch, joignable par email: sandro.imesch@iwm.ch
            </Typography>


    <Typography paragraph fontSize="1.1rem" color="text.secondary">Hébergeur du site:</Typography>
    <Typography paragraph >
        Silicom Group, qui est domicilié à: ch. des Fayards 2, 1032 Romanel-sur-Lausanne et joignable par téléphone au +41 58 861 81 81
    </Typography>

            <Typography paragraph fontSize="1.1rem" color="text.secondary"> Propriété intellectuelle et contrefaçons: </Typography>
            <Typography paragraph>
                L’entreprise IWM AG est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment : les textes, les images, les graphismes, le logo, les icônes,…

                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de l’entreprise IWM AG.

                Toute exploitation non autorisée du site ou d’un quelconque élément qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions du Code de Propriété Intellectuelle.
<Typography/>
                <br></br>
                <Typography paragraph fontSize="1.1rem" color="text.secondary"> Cookies et traceurs:</Typography>

                <Typography paragraph>
                Le site internet www.iwm.ch possède une fonction de partage sur les réseaux sociaux.

                En application de la directive européenne dite « paquet télécom », les internautes doivent être informés et donner leur consentement préalablement à l’insertion de traceurs (plus couramment appelés « cookies »). Les internautes doivent disposer d’une possibilité de choisir de ne pas être tracés lorsqu’ils visitent un site ou utilisent une application. Les éditeurs de sites internet ont donc l’obligation de solliciter au préalable le consentement des utilisateurs.

                Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’internaute peut toutefois configurer son navigateur internet pour refuser l’installation des cookies. Ce site n'utilise pas de cookies.
                </Typography>

            </Typography>
            <Typography paragraph fontSize="1.1rem" color="text.secondary"> Mesure d’audience: </Typography>
            <Typography paragraph>

                La mesure d’audience est faite au niveau du serveur qui permettent de connaître et d’analyser les statistiques de trafic sur le site internet : le nombre de visites, la provenance des visites,…
                 </Typography>
            <Typography paragraph fontSize="1.1rem" color="text.secondary"> Réseaux sociaux: </Typography>
            <Typography paragraph>


                Les cookies liés aux réseaux sociaux sont associés aux boutons qui facilitent le partage des pages et articles sur les réseaux sociaux.

                Formulaire de contact et commentaires

                Vous pouvez être amené à nous indiquer votre adresse e-mail lorsque vous remplissez notre formulaire de contact ou déposez un commentaire sur l’un des articles du site site iwm.ch.

                En aucun cas, votre adresse e-mail ne sera cédée à des tiers.

            </Typography>
            <Typography paragraph fontSize="1.1rem" color="text.secondary">  Liens hypertextes: </Typography>

            <Typography paragraph>


                Ce site internet contient un certain nombre de liens hypertextes vers d’autres sites. Cependant, iwm.ch n’a pas la possibilité de suivre et vérifier le contenu de ces sites, et n’assumera en conséquence aucune responsabilité de ce fait.


            </Typography>



            </Box>
            </Box>


        </div>

    );
}

export default Impressum;
