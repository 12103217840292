import React from 'react';
import servicesDetails from "../store/Services/ServiceDetails";
import ImageCard from "../components/ImageCard";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


function Accredidation() {
    const {t}=useTranslation();
    return (
        <Box
            bgcolor="#7aacdf"
            flex={4}
            p={2}
            sx={{display:{xs:"block",sm:"block"}}}
        >
            <Typography sx={{margin:1}} fontSize="1.2rem" color="text.primary">

                { t("TitleAccreditation")}

            </Typography>

            <Box
                sx={{justifyItems:'center',
                    alignItems:'',
                    flexDirection: 'row',
                    display:'inline-flex',
                    flexWrap: 'wrap',


                }}>

                {servicesDetails.map(serviceDetail =>(
                    (serviceDetail.category==="accreditation"?
                        <ImageCard img={serviceDetail.img} title={serviceDetail.title} text={serviceDetail.text} text2={serviceDetail.text2} text3={serviceDetail.text3}/>
                        :false)

                ))}
            </Box>
        </Box>
    );
}

export default Accredidation;
