const services =[

    {
        title: 'Zerstörungsfreie Prüfungen',
        text: 'ZfP',
        text2:'',
        img: process.env.PUBLIC_URL + 'images/DSC5945.jpg',
        link:'Ndt'


    },
    {
        title: 'Drahtseilprüfungen',
        text: 'MRT',
        text2:'',
        img: process.env.PUBLIC_URL + 'images/DSC5112.jpg',
        link:"Ropes"

    },
    {
        title: 'Zerstörende Prüfungen',
        text: 'Dt',
        text2:'',
        img: process.env.PUBLIC_URL + 'images/zp.jpg',
        link:"Dt"

    },

    {
    title: 'Technische Beratung',
    text: 'Technische Beratung',
    text2:'',
    img: process.env.PUBLIC_URL + 'images/consulting.avif',
        link:"Consulting"

},

    {
        title: 'Netzwerk BKW',
        text: 'Netzwerk BKW',
        text2:' ',

        img: process.env.PUBLIC_URL + 'images/bkw_Netzwerk.jpg',
        link:"BKW_Web"

    },









];


export default services;
