import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import services from "../store/Services/Services";
import BasicCard from "../components/BasicCard";

import VideoHelper from "../helpers/VideoHelper";
import imagesHome from "../store/Diaporama/imagesHome";
import HomeCard from "../components/HomeCard";


function Feed() {
    const [name,setName]=useState(Object)
    const[checked,setChecked]=useState(true);

    useEffect(() => {
        setInterval(() => {
            random_item(imagesHome)
            setName(random_item(imagesHome))
        }, 4000);
    }, []);

    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 12000);
    }, []);

    function random_item(imagesHome:any)
    {

        return (imagesHome[Math.floor(Math.random()*imagesHome.length)]);

    }


    return (
        <Box
        bgcolor="#7aacdf"
        flex={4}
        p={2}
        sx={{display:{xs:"block",sm:"block"}}}
        >
            <Box sx={{display:{xs:"none",sm:"block"}}}>
                {checked? (

                    <Box >
                        <Box sx={{display:'flex'}}>
                            <video src={process.env.PUBLIC_URL + 'images/video.mp4'} width="10000" style={{width:"100%",height:"auto"}} autoPlay muted />
                        </Box>
                    </Box>):<VideoHelper/>}
                <Box sx={{marginTop:"5px"}}>
                <div style={{width:"100%",height:"auto"}}>
                    <HomeCard img={name.img}/>

                </div>
                </Box>
            </Box>
            <Box
                sx={{justifyItems:'center',
            alignItems:'center',
            flexDirection: 'row',
            display:'inline-flex',
            flexWrap: 'wrap',

                }}
            >


                { services.map(service=>(
<BasicCard img={service.img}
           title={service.title}
           link={service.link}

/>
                ))  }

            </Box>
        </Box>

    );
}

export default Feed;
