const servicesDetails =[

    {
        title: 'MT-Prüfung (Magnetpulverprüfung)',
        text: 'MT_description',
        text2:'MT_use',
        text3:'general_zfp',
        img: process.env.PUBLIC_URL + 'images/DSC5945.jpg',
        category:'zfp',

    },
    {
        title: 'PT-Prüfung (Eindringprüfung)',
        text: 'PT_description',
        text2:'PT_use',
        text3:'general_zfp',
        img: process.env.PUBLIC_URL + 'images/pt.png',
        category:'zfp',

    },

    {
        title: 'UT-Prüfung (Ultraschallprüfung)',
        text: 'UT_description',
        text2:'UT_use',
        text3:'general_zfp',
        img: process.env.PUBLIC_URL + 'images/ut.png',
        category:'zfp',

    },
    {   title: 'VT-Prüfung',
        text: 'VT_description',
        text2:'VT_use',
        text3:'general_zfp',
        img: process.env.PUBLIC_URL + 'images/vt.png',
        category:'zfp',

    },

    {   title: 'ZP-Prüfung',
        text: 'ZP_description',
        text2:'ZP_use',
        text3:'general_zp',
        img: process.env.PUBLIC_URL + 'images/zp.jpg',
        category:'dt',

    },

    {   title: 'SVT_title',
        text: 'SVT_description',
        text2:'SVT_use',
        text3:'general_ropes',
        img: process.env.PUBLIC_URL + 'images/DSC5459.jpg',
        category:'ropes',

    },
    {   title: 'MRT_title',
        text: 'MRT_description',
        text2:'MRT_use',
        text3:'general_ropes',
        img: process.env.PUBLIC_URL + 'images/DSC5112.jpg',
        category:'ropes',

    },
    {   title: 'consulting_title',
        text: 'consulting_description',
        text2:'consulting_use',
        text3:'general_consulting',
        img: process.env.PUBLIC_URL + 'images/consulting.avif',
        category:'consulting',

    },
    {
        title: 'TitleAccredidation',
        text: 'accr_description',
        text2:'accr_use',
        text3:'accr_text3',
        img: process.env.PUBLIC_URL + 'images/sas.jpg',
        category:'accreditation',

    }












];


export default servicesDetails;
