import {Card, CardMedia, Typography} from '@mui/material';
import React from 'react';
import  { useTranslation } from 'react-i18next';
import {ButtonBase} from "@mui/material";




interface content {
img:string;


}





function ImageCard(activities:content) {
    const { t } = useTranslation();

    return (

        <Card sx={{  margin:0 }}>
            <div style={{ position: "relative" }}>
            <CardMedia
                component="img"
                width="100%"
                height="auto"
                image={activities.img}
                alt={activities.img}
            />

            <Typography   style={{fontSize:"3rem",position: "absolute", color: "#56a7b2",top: 150,left: "50%",transform: "translateX(-50%)",}}> {t("")}</Typography>

            </div>
        </Card>

    );
}

export default ImageCard;
