import React from 'react';
import {Suspense} from "react";
import ReactDOM from 'react-dom/client';

import App from './App';

import "./translation/i18n";




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>


      <Suspense fallback="... is loading">
<App/>
      </Suspense>



  </React.StrictMode>
);
