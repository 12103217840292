const positions=[

    {
        longitude: 6.964776,
        latitude: 46.244771,
        place: "IWM AG",
        type:"Route de Pré-Bérard 26",
        color:"primary",
        date:"1870 Monthey",
    },



];

export default positions;
