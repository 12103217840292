import React from 'react';
import Feed from "../layout/Feed";
import RightBar from "../layout/RightBar";
import {Stack} from "@mui/material";

function Home() {
    return (
        <div>

            <Stack direction="row">
                <Feed/>
                <RightBar/>
            </Stack>
        </div>
    );
}

export default Home;
