import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import Divider from '@mui/material/Divider';
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";

export default function AddressCard() {
    const {t}=useTranslation();
    return (
        <List
            sx={{
                minHeight: 200,
                maxWidth: 450,
                bgcolor: 'background.paper',
                margin:1
            }}
        >
            <ListItem>
                <Typography fontSize="1.2rem" color="text.secondary">

                    { t("address")} :

                </Typography>
            </ListItem>
            <ListItem>
                <ListItemAvatar>

                    <Avatar>
                        <IconButton onClick={() => {

                            window.open("tel:+41244723003")
                        }}>
                        <PhoneIcon />
                        </IconButton>
                    </Avatar>

                </ListItemAvatar>
                <Box sx={{alignItems:'center',
                    flexDirection: 'row',
                    display:'inline-flex',
                    flexWrap: 'wrap'}}>
                    <ListItemText onClick={() => {

                        window.open("tel:+41244723003")
                    }} primary="024 472 30 03"  />
                </Box>

            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <IconButton onClick={() => {

                            window.open("mailto:institut@iwm.ch")
                        }}>
                        <EmailIcon />
                        </IconButton>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText onClick={() => {

                    window.open("mailto:institut@iwm.ch")
                }} primary="institut@iwm.ch" secondary="" />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemAvatar>
                    <Avatar
                        onClick={() => {

                            window.open("https://www.google.ch/maps/place/IWM+AG/@46.2449495,6.9638672,17z/data=!3m1!4b1!4m6!3m5!1s0x478ebc6058c0fad1:0x34ad2267ce5f723a!8m2!3d46.2449458!4d6.9660559!16s%2Fg%2F1ptxyp99z")}}

                    >
                        <IconButton>
                        <PlaceIcon/>
                        </IconButton>
                    </Avatar>
                </ListItemAvatar>
                <Box sx={{alignItems:'center',
                    flexDirection: 'row',
                    display:'inline-flex',
                    flexWrap: 'wrap'}}>
                <ListItemText  primary="Route de Pré-Bérard 26, 1870 Monthey"
                               onClick={() => {

                                   window.open("https://www.google.ch/maps/place/IWM+AG/@46.2449495,6.9638672,17z/data=!3m1!4b1!4m6!3m5!1s0x478ebc6058c0fad1:0x34ad2267ce5f723a!8m2!3d46.2449458!4d6.9660559!16s%2Fg%2F1ptxyp99z")}}
                />

                </Box>
            </ListItem>
        </List>
    );
}
