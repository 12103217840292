import React from 'react';
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import EmailIcon from "@mui/icons-material/Email";




interface ContactType {
    name:string,
    mail: string,
    phone:string,
    area:string,

}



function ContactCard(contact:ContactType) {
    return (
        <Card sx={{ minHeight: 250,
            maxWidth: 400, margin:1,  textDecoration: 'none'}}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {contact.area}
                </Typography>
                <Typography variant="h5" color="text.primary">
                    {contact.name}
                </Typography>

                <Typography variant="h5" color="text.primary">

                </Typography>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <IconButton onClick={() => {

                            window.open("mailto:"+contact.mail)
                        }}>
                            <EmailIcon />
                        </IconButton>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText onClick={() => {

                    window.open("mailto:"+contact.mail)
                }} primary={contact.mail} secondary="" />
            </ListItem>

            <ListItem>
                <ListItemAvatar>

                    <Avatar>
                        <IconButton onClick={() => {

                            window.open("tel:+41244723003")
                        }}>
                            <PhoneIcon />
                        </IconButton>
                    </Avatar>

                </ListItemAvatar>
                <ListItemText onClick={() => {

                    window.open("tel:+41244723003")
                }} primary={contact.phone} secondary="" />

            </ListItem>
                </CardContent>

        </Card>

    );
}

export default ContactCard;
