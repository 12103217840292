import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import  { useTranslation } from 'react-i18next';


function VideoHelper() {
    const { t } = useTranslation();
    const[checked,setChecked]=useState(true);
    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 20000);
    }, []);

    return (
        <div>
            {checked? (

                <Button color="primary" sx={{ m: 1, width: '25ch' }}
                        type="submit"
                        variant="contained"
                        onClick={() => {

                            window.open("https://www.youtube.com/watch?v=qPGToxCM2Oo")
                        }}

                >
                    {t(`${"video"}`)}
                </Button>
                ):null}


        </div>
    );
}

export default VideoHelper;
