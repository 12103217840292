const contacts =[

    {
       name:"Remo Aeschlimann",
        mail:"remo.aeschlimann@iwm.ch",
        phone:"+41 24 472 30 03",
        area:"Schweiz",
        lng:"de",

    },
    {
        name:"Remo Aeschlimann",
        mail:"remo.aeschlimann@iwm.ch",
        phone:"+41 24 472 30 03",
        area:"Suisse",
        lng:"fr",

    },
    {
        name:"Gian Bänninger",
        mail:"gian.baenninger@iwm.ch",
        phone:"+41 24 472 30 03",
        area:"Graubünden, Ostschweiz, Tessin",
        lng:"de",

    },
    {
        name:"Sandro Imesch",
        mail:"sandro.imesch@iwm.ch",
        phone:"+41 24 472 30 03",
        area:"Oberwallis",
        lng:"de",

    },
    {
        name:"David Robin",
        mail:"david.robin@iwm.ch",
        phone:"+41 24 472 30 03",
        area:"Suisse Romande, France",
        lng:"fr",

    },








];


export default contacts;
