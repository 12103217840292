import React from 'react';
import AddressCard from "../components/AddressCard";
import StaticMap from "../components/map/StaticMap";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

function Address() {
    const {t}=useTranslation();
    return (
        <div>
            <Typography sx={{margin:1}}fontSize="1.2rem" color="text.primary">

                { t("TitleAddress")}

            </Typography>
            <AddressCard/>
            <StaticMap/>
        </div>
    );
}

export default Address;
