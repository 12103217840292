import React from 'react';
import {Button, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

interface content {
    img:string;
    title:string;
    link:string;

}

function BasicCard(infos:content) {
    const {t}=useTranslation();

    return (
        <Card sx={{ maxWidth: 345, margin:1
        }}>

            <CardMedia
                component="img"
                height="194"
                image={infos.img}
                alt="image"
            />
            <CardContent>
                <Typography fontSize="1.2rem" color="text.secondary">

                    { t(`${infos.title}`)}

                </Typography>
                <CardContent>
                    <Typography >

                    </Typography>
                </CardContent>

                {infos.title==="Netzwerk BKW" ? <Button variant="contained" color="primary"  onClick={() => {window.open(   t(`${infos.link}`) )}}>
                    { t(`${infos.title}`)}</Button>:
                    (<Link to={"/"+infos.link} style={{textDecoration:"none"}} > <Button variant="contained" color="primary"  > { t(`${infos.title}`)}</Button></Link>)}

                </CardContent>
        </Card>
    );
}

export default BasicCard;
