import React from 'react';
import ContactCard from "../components/ContactCard";
import contacts from "../store/ContactsPersons/ContactPerson";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import i18next from "i18next";


function ContactPerson() {


    const {t}=useTranslation();



    return (

        <Box
            bgcolor="#7aacdf"
            flex={4}
            p={2}
            sx={{display:{xs:"block",sm:"block"}}}>
            <Typography fontSize="1.2rem" color="text.primary">

                { t("TitleContact")}

            </Typography>

            <Box
                sx={{justifyItems:'center',
                        alignItems:'center',
                        flexDirection: 'row',
                        display:'inline-flex',
                        flexWrap: 'wrap',}}>

            {contacts.map(contact =>(
                (contact.lng===i18next.language?
                <ContactCard name={contact.name} mail={contact.mail} phone={contact.phone} area={contact.area}/>
                        :false)
            ))}
        </Box>
        </Box>

    );
}

export default ContactPerson;
