import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React from 'react';
import  { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";


interface content {
    img:string;
    title:string;
    text:string;
    text2:string;
    text3:string;

}




function ImageCard(services:content) {
    const { t } = useTranslation();

    return (
        <Card sx={{ maxWidth: 345, margin:1 }}>

            <CardMedia
                component="img"
                height="194"
                image={services.img}
                alt="image"
            />
            <CardContent>
                <Typography fontSize="1.2rem" color="text.secondary">

                    {t(`${services.title}`)}

                </Typography>
            </CardContent>


            <CardContent>
                <Typography paragraph></Typography>
                <Typography paragraph>
                    { t(`${services.text}`)}
                </Typography>

                {services.text2 ?
                    <Typography paragraph fontSize="1.1rem" color="text.primary">
                        {t(`${services.text2}`)}

                    </Typography>
                    :null }
                {services.text3 ?
                    <Typography paragraph fontSize="1.0rem" color="text.secondary">
                        { t(`${services.text3}`)}

                    </Typography>
                    :null }

            </CardContent>

        </Card>
    );
}

export default ImageCard;
