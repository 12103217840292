import React from 'react';


import Box from "@mui/material/Box";

import services from "../store/Services/Services";
import BasicCard from "../components/BasicCard";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


function Service() {
    const {t}=useTranslation();
    return (

        <Box
            bgcolor="#7aacdf"
            flex={4}
            p={2}
            sx={{display:{xs:"block",sm:"block"}}}
        >
            <Typography fontSize="1.2rem" color="text.primary">

                { t("TitleService")}

            </Typography>
            <Box
                sx={{justifyItems:'center',
                    alignItems:'center',
                    flexDirection: 'row',
                    display:'inline-flex',
                    flexWrap: 'wrap',

                }}
            >

                { services.map(service=>(
                    <BasicCard img={service.img}
                               title={service.title}
                               link={service.link}
                    />

                ))  }

            </Box>
        </Box>

    );
}

export default Service;
