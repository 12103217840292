

const imagesHome = [
    {
        img: process.env.PUBLIC_URL + 'images/DSC5112.jpg',

    },
    {
        img: process.env.PUBLIC_URL + 'images/DSC5459.jpg',

    },
    {
        img: process.env.PUBLIC_URL + 'images/DSC5945.jpg',

    },


    {
        img: process.env.PUBLIC_URL + 'images/DSC6219.jpg',

    },









];
export default imagesHome;
