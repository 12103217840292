import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import CopyrightIcon from '@mui/icons-material/Copyright';
import {Link} from "react-router-dom";

export default function SimpleBottomNavigation() {
    const [value, setValue] = React.useState(0);

    return (
        <Box sx={{ display:{ md: 'flex' }, alignItems:'center',justifyContent:'center', }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction onClick={() => {

                    window.open("tel:+41244723003")
                }} label="Phone" icon={<PhoneIcon />} />
                <BottomNavigationAction onClick={() => {

                    window.open("mailto:institut@iwm.ch")
                }} label="Mail" icon={<MailIcon />} />
                <Link to={"/impressum"}>
                <BottomNavigationAction label="IWM AG" icon={<CopyrightIcon />} />
                </Link>
            </BottomNavigation>
        </Box>
    );
}
