import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../logo/logo512.png';
import logosmall from '../logo/logo_small.png';
import LanguageIcon from '@mui/icons-material/Language';
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";



const pages = ['services', 'accreditation','contact', 'address'];
const lngs: { [key:string]:any } = {
    de: { nativeName: 'Deutsch' ,shortName:'de'},
    fr: { nativeName: 'Français',shortName:'fr' }
};


const ResponsiveAppBar = () => {
     const { t } = useTranslation();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };





    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >

                        <img  alt={"logo"} src={logo} height={50}/>

                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label=""
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                            {pages.map((page) => (
                                <Link style={{textDecoration:"none",color:"black"}} to={page}>
                                <MenuItem key={page} onClick={handleCloseNavMenu}>

                                    <Typography textAlign="center">{t( `${page}`)}</Typography>

                                </MenuItem>
                                </Link>
                                 ))}
                        </Menu>
                    </Box>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >

                        <img alt={"logos"} src={logosmall} height={45}/>


                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Link style={{textDecoration:"none",color:"white"}} to={page}>
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >

                                {t( `${page}`)}

                            </Button>
                            </Link>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>

                        {i18n.resolvedLanguage==="fr"?

                            <Button   size="small" sx={{ fontWeight: 'bold', fontSize: { xs: '0.6rem', lg: '0.8rem' },my:2,color:'white'}} type="submit" onClick={() => i18n.changeLanguage("de")}>
                                {"Deutsch"}
                            </Button>
                            : <Button   size="small" sx={{ fontWeight: 'bold', fontSize: { xs: '0.6rem', lg: '0.8rem' },my:2,color:'white'}} type="submit" onClick={() => i18n.changeLanguage("fr")}>
                                {"Français"}
                            </Button>
                        }


                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;


