import React from 'react';
import {Button} from '@mui/material';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";


function Error() {
    const {t}=useTranslation();

    return (
        <div>
            <Box sx={{alignItems:"center"}}>
                <Box>
            404 Error: this page doesn't exist.
                    <Link style={{textDecoration:"none"}}  to={"/home"}>
            <Button>
                { t("back")}
            </Button>
                    </Link>
                </Box>
            </Box>
        </div>
    );
}

export default Error;
