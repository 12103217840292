import Map, {GeolocateControl, Marker} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {apiAccessToken} from "./config";
import {mapStyle} from "./config";
import positions from "../../store/Address/position";
import {LocationOn} from "@mui/icons-material";






function StaticMap() {

    return (

            <Map
                mapboxAccessToken={apiAccessToken}
                initialViewState={{
                    longitude: 6.964776,
                    latitude: 46.244771,
                    zoom: 13,
                }}
                mapStyle={mapStyle}
                style={{width: 400, height: 400,margin:1}}

                onClick={() => {

                    window.open("https://www.google.ch/maps/place/IWM+AG/@46.2449495,6.9638672,17z/data=!3m1!4b1!4m6!3m5!1s0x478ebc6058c0fad1:0x34ad2267ce5f723a!8m2!3d46.2449458!4d6.9660559!16s%2Fg%2F1ptxyp99z")
                }}

            >

                <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}

                    trackUserLocation={true}
                />

                {positions.map(props => (



                <Marker
                    longitude={props.longitude}
                    latitude={props.latitude}
                    title={props.place}

                >

                     <LocationOn fontSize="large" color={props.color}   />


                    </Marker>



                ))}

            </Map>




    );
}
export default StaticMap;
