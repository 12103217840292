import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./page/Home";
import Service from "./page/Service";
import ContactPerson from "./page/ContactPerson";
import ResponsiveAppBar from "./navigation/ResponsiveAppBar";
import SimpleBottomNavigation from "./navigation/SimpleBottomNavigation";
import Address from "./page/Address";
import Ndt from "./page/Ndt";
import Ropes from "./page/Ropes";
import Dt from "./page/Dt";
import Consulting from "./page/Consulting";
import ScrollToTop from "./helpers/ScrollToTop";
import Accreditation from "./page/Accreditation";
import Error from "./page/Error";
import Impressum from "./page/Impressum";





function App() {

  return (



    <BrowserRouter>

        <ResponsiveAppBar/>
<ScrollToTop/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="home" element={<Home/>}/>
            <Route path="services" element={<Service/>}/>
            <Route path="contact" element={<ContactPerson />} />
            <Route path="/address" element={<Address />} />
            <Route path="/Ndt" element={<Ndt/>}/>
            <Route path="/Ropes" element={<Ropes/>}/>
            <Route path="/Dt" element={<Dt/>}/>
            <Route path="/Consulting" element={<Consulting/>}/>
            <Route path="/accreditation" element={<Accreditation/>} />
            <Route path="/impressum" element={<Impressum/>}/>
            <Route path="/wp" element={<Home/>}/>
            <Route path="/*" element={<Error/>}/>

        </Routes>
        <SimpleBottomNavigation/>
    </BrowserRouter>




  );
}

export default App;
